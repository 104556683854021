import styled from "styled-components";

import { device } from "../../../utils/breakpoints";

export const Button = styled.button`
  background: #e6ecff;
  color: #013cff;
  width: 100%;
  height: 64px;
  font-size: 18px;
  border-radius: 4px;
  border: none;
  transition: 1s;
  margin: 0 0 30px 0;
  font-weight: 700;
  padding: 5px 0 0 0;
  line-height: 23.76px;
  transition: 0.3s;

  &:hover {
    background: #ffffff;
  }

  &:active {
    filter: brightness(1.32);
  }

  &:disabled {
    filter: brightness(1.6);
  }

  @media ${device.tablet} {
    width: 260px;
    background: #e6ecff;
    padding: 0;
  }

  @media ${device.tabletLandscape} {
    margin: 0 0 59px 0;
    width: 260px;
    background: #e6ecff;
    padding: 0;
  }

  @media ${device.computer} {
    margin: 20px 0 0 0;
    width: 260px;
    background: #e6ecff;
  }

  @media ${device.desktop} {
    margin: 20px 0 0 0;
    width: 260px;
  } ;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media ${device.tablet}, ${device.tabletLandscape} {
    display: block;
  } ;
`;
