import styled from "styled-components";
import { TEXT_BUTTON_1 } from "../../../assets/styles/typography";

import { device } from "../../../utils/breakpoints";

export const Wrapper = styled.div`
  background: #013cff;
  color: white;
  height: 168px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  @media ${device.tabletLandscape} {
    width: 287px;
  }

  @media ${device.tablet} {
    width: 223px;
  }

  @media ${device.tablet}, ${device.tabletLandscape} {
    height: 110px;
    position: fixed;
    bottom: 0;
    left: 0;
  }

  @media ${device.computer} {
    height: 141px;
    width: 363px;
    position: fixed;
    bottom: 0;
    left: 0;
  }

  @media ${device.desktop} {
    height: 181px;
    width: 390px;
  } ;
`;

export const TextWrapper = styled.span`
  ${TEXT_BUTTON_1}
  margin-top: 20px;

  @media ${device.tablet}, ${device.tabletLandscape} {
    display: none;
  } ;
`;

export const IconsWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  justify-content: space-evenly;
  align-items: center;

  @media ${device.tablet}, ${device.tabletLandscape} {
    align-items: center;
  }

  @media ${device.computer} {
    width: 80%;
  }

  @media ${device.desktop} {
  } ;
`;

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: #013cff;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    filter: brightness(1.76);
  }

  &:active {
    filter: brightness(1.68);
  }

  &:focus {
    filter: brightness(1.68);
  }

  a {
    display: flex;
    align-items: center;
    width: 30px;
    height: 30px;
    margin: 0;
    padding: 0;
  }
`;

export const RodoLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 48px;
  background: #013cff;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    filter: brightness(1.76);
  }

  &:active {
    filter: brightness(1.68);
  }

  &:focus {
    filter: brightness(1.68);
  }

  a {
    display: flex;
    align-items: center;
    height: 30px;
    margin: 0;
    padding: 0;
    color: #fff;
    font-weight: 900;
    text-decoration: none;
  }
`;
