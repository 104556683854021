import styled from "styled-components";

import { device } from "../../../utils/breakpoints";

export const Button = styled.button`
  background: #ff7500;
  color: white;
  width: 100%;
  height: 56px;
  font-size: 18px;
  border-radius: 4px;
  border: none;
  transition: 1s;
  margin: 40px 0 20px 0;
  font-weight: 700;
  line-height: 23.76px;
  transition: 0.3s;

  &:hover {
    filter: brightness(1.24);
  }

  &:active {
    filter: brightness(1.32);
  }

  &:disabled {
    filter: brightness(1.6);
  }

  @media ${device.tablet} {
    width: 260px;
  }

  @media ${device.tabletLandscape} {
    margin: 40px 0 30px 0;
    width: 260px;
  }

  @media ${device.computer} {
    width: 260px;
    margin: 10px 0 0 0;
  }

  @media ${device.desktop} {
    width: 260px;
    margin: 20px 0 0 0;
  } ;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media ${device.tablet}, ${device.tabletLandscape} {
    display: block;
  } ;
`;
