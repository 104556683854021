import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, ButtonWrapper } from "./style";
import Modal from "../Modal";
import ContactPage from "../Navigation/Contact";

interface ContactButtonProps {
  setModalState: (value: boolean) => void;
}

const ContactButton: FC<ContactButtonProps> = ({ setModalState }) => {
  const [openContact, setOpenContact] = useState<boolean>(false);
  const [transitionOpenContact, setTransitionOpenContact] = useState<boolean>(
    false
  );

  const { t } = useTranslation("contact");

  const handleOpenContactModal = () => {
    setTransitionOpenContact(true);
    setOpenContact(true);
    setModalState(true);
  };

  const handleCloseContactModal = () => {
    setTransitionOpenContact(false);
    setTimeout(() => {
      setOpenContact(false);
    }, 600);
    setModalState(false);
  };

  return (
    <>
      <Modal
        open={openContact}
        handleClose={handleCloseContactModal}
        style={{ background: "none", overflowY: "auto", width: "100%" }}
      >
        <ContactPage
          transitionOpen={transitionOpenContact}
          handleClose={handleCloseContactModal}
        />
      </Modal>
      <ButtonWrapper>
        <Button onClick={handleOpenContactModal}>
          {t(`contactUs_title`).toUpperCase()}
        </Button>
      </ButtonWrapper>
    </>
  );
};

export default ContactButton;
