import React from "react";
import { useTranslation } from "react-i18next";

import {
  Wrapper,
  TextWrapper,
  IconsWrapper,
  LinkWrapper,
  RodoLink,
} from "./style";
import FacebookIcon from "-!svg-react-loader!../../../assets/images/contact/facebook.svg";
import LinkedInIcon from "-!svg-react-loader!../../../assets/images/contact/linkedin.svg";
import YouTubeIcon from "-!svg-react-loader!../../../assets/images/contact/youtube.svg";
import MediumIcon from "-!svg-react-loader!../../../assets/images/contact/medium1.svg";

const SMWidget = () => {
  const { t } = useTranslation("main");

  return (
    <Wrapper>
      <TextWrapper>{t(`smWidget_title`)}</TextWrapper>
      <IconsWrapper>
        <LinkWrapper>
          <a
            href="https://facebook.com/okepoland/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon />
          </a>
        </LinkWrapper>
        <LinkWrapper>
          <a
            href="https://linkedin.com/company/oke-poland"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon />
          </a>
        </LinkWrapper>
        <LinkWrapper>
          <a
            href="https://www.youtube.com/channel/UC40pBzWzKArfXKg-q9_irhg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <YouTubeIcon />
          </a>
        </LinkWrapper>
        <LinkWrapper>
          <a
            href="https://medium.com/oke-software"
            target="_blank"
            rel="noopener noreferrer"
          >
            <MediumIcon />
          </a>
        </LinkWrapper>
      </IconsWrapper>
      <RodoLink>
        <a href="https://oke.pl/rodo" target="_blank" rel="noopener noreferrer">
          RODO
        </a>
        </RodoLink>
        <RodoLink>
            <a href="https://oke.pl/internalProcedure" target="_blank" rel="noopener noreferrer">
                  {t(`procedura_title`)}
            </a>
        </RodoLink>
    </Wrapper>
  );
};

export default SMWidget;
