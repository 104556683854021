import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, ButtonWrapper } from "./style";
import Modal from "../Modal";
import NewsPage from "../NewsPage";

interface ContactButtonProps {
  setModalState: (value: boolean) => void;
}

const ContactButton: FC<ContactButtonProps> = ({ setModalState }) => {
  const [openNews, setOpenNews] = useState<boolean>(false);
  const [transitionOpenNews, setTransitionOpenNews] = useState<boolean>(
    false
  );

  const { t } = useTranslation("main");

  const handleOpenNewsModal = () => {
    setTransitionOpenNews(true);
    setOpenNews(true);
    setModalState(true);
  };

  const handleCloseNewsModal = () => {
    setTransitionOpenNews(false);
    setTimeout(() => {
      setOpenNews(false);
    }, 600);
    setModalState(false);
  };

  return (
    <>
      <Modal
        open={openNews}
        handleClose={handleCloseNewsModal}
        style={{ background: "none", overflowY: "auto", width: "100%" }}
      >
        <NewsPage
          transitionOpen={transitionOpenNews}
          handleClose={handleCloseNewsModal}
        />
      </Modal>
      <ButtonWrapper>
        <Button onClick={handleOpenNewsModal}>
          {t(`news_title`).toUpperCase()}
        </Button>
      </ButtonWrapper>
    </>
  );
};

export default ContactButton;
