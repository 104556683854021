import styled, { css } from "styled-components";
import Img, { FluidObject } from "gatsby-image";

import {
  H2,
  H3,
  SUBTITLE_1,
  SUBTITLE_2,
  SUBTITLE_3_MOBILE,
} from "../../../assets/styles/typography";
import { device } from "../../../utils/breakpoints";
import { fadeIn, slideToRight } from "../../../utils/animations";
import MobileLogoImg from "-!svg-react-loader!../../../assets/images/Logo/mobileLogo.svg";
import DesktopLogoImg from "-!svg-react-loader!../../../assets/images/Logo/desktopLogo.svg";
import TopVectorImg from "-!svg-react-loader!../../../assets/images/vectors/topVector.svg";
import BottomVectorImg from "-!svg-react-loader!../../../assets/images/vectors/bottomVector.svg";

export const TransitionWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-template-rows: repeat(5, auto);
  z-index: 1000;
  pointer-events: none;
`;

export const TransitionBlock = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  margin-left: auto;
  background: #ffffff;
  z-index: 1;
`;

interface TransitionBlockProps {
  delay: number;
}

export const TransitionRandomBlock = styled.div`
  flex: 1;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  background: #ffffff;
  z-index: 1;
  opacity: 0;
  animation-duration: ${(props: TransitionBlockProps) => `${props.delay}ms`};
  animation-fill-mode: forwards;
`;

interface PageProps {
  animation?: string;
}

export const PageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100vh);
  color: #151515;
  background: #ffffff;

  .whiteBlock {
    animation: ${(props: PageProps) =>
      props.animation === "next"
        ? css`
            ${fadeIn} 300ms linear
          `
        : css`
            ${slideToRight} 300ms ease-in
          `};
    animation-fill-mode: forwards;
    animation-delay: ${(props: PageProps) =>
      props.animation !== "next" && "0.7s"};

    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(11),
    &:nth-child(14),
    &:nth-child(19) {
      animation-delay: ${(props: PageProps) =>
        props.animation !== "next" && "0.1s"};
    }

    &:nth-child(0),
    &:nth-child(1),
    &:nth-child(8),
    &:nth-child(9),
    &:nth-child(13),
    &:nth-child(16) {
      animation-delay: ${(props: PageProps) =>
        props.animation !== "next" && "0.4s"};
    }
  }

  @media ${device.computer}, ${device.desktop} {
    padding-bottom: 0;
    height: 100vh;
    display: flex;
  } ;
`;

export const NavigationBar = styled.div`
  height: 72px;
  position: fixed;
  top: 0;
  width: 100%;
  background: #ffffff;
  overflow: hidden;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 16px 4px rgba(29, 29, 27, 0.04);
`;
export interface LogoWrapperProps {
  show?: number;
}

export const MobileNavigationBar = styled.div`
  height: 72px;
  position: fixed;
  top: 0;
  width: 100%;
  background: #ffffff;
  overflow: hidden;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 16px 4px rgba(29, 29, 27, 0.04);
`;

export const Navbar = styled.div`
  height: 72px;
  display: flex;
  align-items: center;

  @media ${device.tablet}, ${device.tabletLandscape} {
    position: absolute;
    top: 88px;
    margin-left: 99px;
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
  }

  @media ${device.computer} {
    position: absolute;
    top: 70px;
    width: 100%;
    margin-left: 120px;
  }

  @media ${device.desktop} {
    top: 120px;
  } ;
`;

export const Content = styled.div``;

interface ContentWrapperProps {
  modal?: string | null;
}

export const ContentWrapper = styled.div`
  width: 100vw;
  padding: 0 24px;
  margin-top: ${(props: ContentWrapperProps) =>
    props.modal && props.modal.length ? "24px" : "72px"};

  @media ${device.tablet} {
    margin: 0 99px 0 99px;
    width: auto;
    padding-top: 160px;
  }

  @media ${device.tabletLandscape} {
    margin: 0 99px 0 99px;
    width: 80%;
    padding-top: 180px;
  }

  @media ${device.computer} {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 48%;
    left: 120px;
    margin: 0;
    top: 27%;
    padding-top: 0;
  }

  @media ${device.desktop} {
    width: 50%;
    left: 15%;
    top: 22%;
  } ;
`;

export const Title = styled.div`
  background: #ffffff;
  padding: 0;

  @media ${device.computer} {
    padding: 0 24px 32px 0;
  }

  @media ${device.desktop} {
    padding: 56px 56px 56px 0;
  } ;
`;

export const PageTitleWrapper = styled.div`
  ${H3}

  @media ${device.desktop} {
    ${H2}
  } ;
`;

export const PageSubtitleWrapper = styled.div`
  ${SUBTITLE_3_MOBILE}
  margin-top: 16px;

  @media ${device.tablet} {
    ${SUBTITLE_2}
  }

  @media ${device.desktop} {
    ${SUBTITLE_1}
  } ;
`;

export const DesktopLogo = styled(DesktopLogoImg)`
  display: none;

  @media ${device.tablet}, ${device.tabletLandscape}, ${device.computer} {
    display: block;
    padding: 0;
    color: #151515;
  } ;
`;

export const LogoMobile = styled(MobileLogoImg)`
  width: 110px;
  height: 24px;

  @media ${device.tablet}, ${device.tabletLandscape}, ${device.computer} {
    display: none;
  } ;
`;

export const Team = styled(Img)<{
  fluid: FluidObject | FluidObject[];
}>`
  postion: relative;
  width: 100%;
  height: 220px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  @media ${device.mobileM} {
    height: 370px;
  }

  @media ${device.tablet} {
    position: relative;
    width: 570px;
    height: 438px;
    margin: 0 auto;
  }

  @media ${device.tabletLandscape} {
    width: 727px;
    height: 560px;
    margin-left: 99px;
    margin-bottom: 99px;
  }

  @media ${device.computer} {
    width: 600px;
    height: 370px;
    margin: auto;
    right: 215px;
  }

  @media ${device.desktop} {
    height: 63%;
    width: 991px;
    right: 250px;
  } ;
`;

export const TopVector = styled(TopVectorImg)`
  display: none;

  @media ${device.computer} {
    position: relative;
    background-size: cover;
    display: block;
    height: 90.32px;
    top: 52px;
    left: 0;
  }

  @media ${device.computer}, ${device.desktop} {
    width: 45%;
  }

  @media ${device.desktop} {
    position: relative;
    background-size: cover;
    display: block;
    height: 126px;
    top: 51px;
    left: 0;
  } ;
`;

export const BottomVector = styled(BottomVectorImg)`
  display: none;

  @media ${device.computer}, ${device.desktop} {
    position: absolute;
    background-size: cover;
    display: block;
    width: 45%;
    right: 0;
  }

  @media ${device.computer} {
    display: block;
    bottom: 11px;
    height: 90.32px;
  }

  @media ${device.desktop} {
    display: block;
    bottom: 19px;
    height: 126px;
  } ;
`;

export const EUModalWrapper = styled.div`
  position: absolute;
  z-index: 1;
`;