import styled, { css } from "styled-components";

import { device } from "../../../utils/breakpoints";
import { fadeIn, fadeOut, scaleUp } from "../../../utils/animations";
import {
  H2,
  H3,
  TEXT_BODY_1,
  TEXT_BODY_2,
} from "../../../assets/styles/typography";

interface WrapperProps {
  open?: number;
}

export const PageWrapper = styled.div`
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  color: #151515;
  background: #ffffff;
  animation: ${(props: WrapperProps) =>
    props.open
      ? css`
          ${fadeIn} 300ms linear
        `
      : css`
          ${fadeOut} 300ms linear
        `};
  animation-fill-mode: forwards;
  padding: 120px 24px 32px 24px;

  svg {
    fill: #151515;
  }

  @media ${device.tablet}, ${device.tabletLandscape} {
    padding: 88px 88px 64px 88px;
  }

  @media ${device.computer} {
    padding: 88px 294px 80px 120px;
  }

  @media ${device.desktop} {
    padding: 128px 294px 120px 120px;
  } ;
`;

export const CloseModalWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 36px;
  height: 36px;
  font-size: 36px;
  margin: 0 0 0 auto;
  user-select: none;
  display: flex;
  align-items: center;

  svg {
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      opacity: 0.24;
    }

    rect {
      fill: #151515;
    }
  }

  @media ${device.tablet}, ${device.tabletLandscape} {
    top: 88px;
    right: 88px;
    width: 40px;
    height: 40px;
    font-size: 40px;
  }

  @media ${device.computer} {
    top: 80px;
    right: 120px;
    width: 40px;
    height: 40px;
    font-size: 40px;
  }

  @media ${device.desktop} {
    top: 80px;
    right: 120px;
  } ;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

interface TitleProps extends WrapperProps {
  color?: string;
}

export const Title = styled.div`
  width: 100%;
  margin: 0;
  font-size: 24px;
  line-height: 28px;
  font-weight: 800;
  color: ${(props: TitleProps) => props.color || "#151515"};
  text-transform: uppercase;
  opacity: 0;
  animation: ${fadeIn} 300ms linear;
  animation-fill-mode: forwards;
  animation-delay: 1s;

  @media ${device.computer} {
    ${H3}
  }

  @media ${device.desktop} {
    ${H2}
  } ;
`;

export const DescriptionsWrapper = styled.div`
  margin-top: 32px;

  @media ${device.tablet}, ${device.tabletLandscape} {
    margin-top: 48px;
  }

  @media ${device.computer} {
    margin-top: 64px;
  }
`;

export const Description = styled.div`
  ${TEXT_BODY_2}
  width: 100%;
  margin: 0;
  color: #151515;
  white-space: pre-line;
  font-weight: normal;
  padding-bottom: 16px;

  &:last-of-type {
    padding-bottom: 0px;
  }

  a,
  a:visited,
  a:link {
    color: #013cff;
    text-decoration: none;
    font-weight: 800;
  }

  @media ${device.tablet}, ${device.tabletLandscape}, ${device.computer} {
    ${TEXT_BODY_1}
  }
`;

export const HorizontalDivider = styled.hr`
  width: 100%;
  height: 2px;
  margin: 24px 0 32px;
  background: #eaeaea;
  transform: scale(0);
  transform-origin: 0 50%;
  animation: ${scaleUp} 1s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
`;
