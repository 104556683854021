import styled from "styled-components";

import { LogoWrapperProps } from "../../pages/MainPage/style";
import { device } from "../../../utils/breakpoints";

export const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 72px;
  width: 100vw;
  height: 48px;
  padding: 0 34px 0 24px;
  background: #e6ecff;
  display: ${(props: LogoWrapperProps) => (props.show ? "flex" : "none")};

  @media ${device.tabletLandscape} {
    padding: 0 79px 0 100px;
  }

  @media ${device.tablet} {
    padding: 0 78px 0 99px;
  }

  @media ${device.tablet}, ${device.tabletLandscape} {
    top: 0;
    height: 64px;
  }

  @media ${device.computer} {
    padding: 0 138px 0 120px;
    height: 50px;
    top: 0;
  }

  @media ${device.desktop} {
    padding: 0 138px 0 120px;
    height: 60px;
  }
`;

export const EULogoLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

export const CloseIconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
