import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";

import { ModalWrapper, EULogoLinkWrapper, CloseIconWrapper } from "./style";
import { PageChange } from "../../common/Scroll";
import UELogo from "-!svg-react-loader!../../../assets/images/UE.svg";

interface EUModalProps {
  isEUModalVisible: boolean;
}

const EUModal: React.FC<EUModalProps> = ({ isEUModalVisible }) => {
  const [pageChange, setPageChange] = useState<PageChange>();

  const goToOurProjectsPage = () => {
    setPageChange("next");
    setTimeout(() => {
      navigate("/ourProjects");
    }, 600);
  };

  return (
    <ModalWrapper show={isEUModalVisible ? 1 : 0}>
      <EULogoLinkWrapper onClick={goToOurProjectsPage}>
        <UELogo />
      </EULogoLinkWrapper>{" "}
    </ModalWrapper>
  );
};

export default EUModal;
