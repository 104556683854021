import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { isSafari } from "react-device-detect";
import { useTranslation } from "react-i18next";

import {
  PageWrapper,
  CloseModalWrapper,
  TitleWrapper,
  Title,
  DescriptionsWrapper,
  Description,
  HorizontalDivider
} from "./style";
import CloseIcon from "-!svg-react-loader!../../../assets/images/closeIcon.svg";
import { fixConjunctions } from "../../../utils/dropTheWord";

interface ImageDescriptionType {
  name: string;
  description: string;
}

interface ContactProps {
  transitionOpen: boolean;
  handleClose: () => void;
}

const Contact: React.FC<ContactProps> = ({ transitionOpen, handleClose }) => {
  const { t } = useTranslation("main");
  const descriptions = t(`triple_cooperation_text`) as unknown as string[];

  return (
    <PageWrapper open={transitionOpen ? 1 : 0}>
      <CloseModalWrapper>
        <CloseIcon onClick={handleClose} />
      </CloseModalWrapper>
      <TitleWrapper>
        {t(`triple_cooperation_title`)
          .split("\n")
          .map((text, index) => (
            <Title
              color={index !== 1 ? "#151515" : "#0500FF"}
              key={index}
            >
              {text}
            </Title>
          ))}
      </TitleWrapper>
      <DescriptionsWrapper>
      {descriptions.map((description, index) => (
        <>
          <Description
            dangerouslySetInnerHTML={{
              __html: fixConjunctions(description),
            }}
            key={index}
          />
          {index !== 3 && <HorizontalDivider />}
        </>
      ))}
      </DescriptionsWrapper>
    </PageWrapper>
  );
};

export default Contact;
